<template>
  <div class="service-form-detail" v-if="getPermission('service-form:view')">
    <v-container fluid class="custom-bthrust-style">
      <v-container>
        <v-layout class="justify-end">
          <div class="mr-2">
            <v-text-field
              v-model.trim="filter.search"
              v-on:click:clear="clearFilter('search')"
              clearable
              dense
              filled
              flat
              label="Search"
              hide-details
              solo
              :disabled="serviceFormLoading"
              color="cyan"
              @keydown.enter="getServiceForm"
              @keydown.tab="getServiceForm"
            ></v-text-field>
          </div>
          <div class="mx-2" v-if="!isDeliveryDetail">
            <v-autocomplete
              v-model.trim="filter.visit"
              hide-details
              :items="visitList"
              clearable
              dense
              flat
              filled
              label="Delivery"
              item-color="cyan"
              color="cyan"
              solo
              :disabled="serviceFormLoading"
              item-text="barcode"
              item-value="id"
              v-on:change="getServiceForm"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    v-html="'No Delivery(s) Found.'"
                  ></v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <p class="m-0 font-size-16 text-ellipsis text-capitalize">
                  {{ item.barcode }} ({{ formatDateTime(item.started_at) }} -
                  {{ formatTime(item.finished_at) }})
                </p>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    class="font-size-16 pl-2 text-ellipsis pb-1 text-capitalize"
                    >{{ item.barcode }} ({{ formatDateTime(item.started_at) }} -
                    {{ formatTime(item.finished_at) }})</v-list-item-title
                  >
                  <v-list-item-subtitle
                    class="font-size-14 pl-2 text-ellipsis pb-1"
                    >{{ item.title }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
          <div class="mx-2" v-if="!isDeliveryDetail">
            <v-autocomplete
              v-model.trim="filter.engineer"
              hide-details
              :items="engineerList"
              clearable
              dense
              flat
              filled
              label="Engineer"
              item-color="cyan"
              color="cyan"
              solo
              :disabled="serviceFormLoading"
              item-text="display_name"
              item-value="id"
              v-on:change="getServiceForm"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    v-html="'No Engineer(s) Found.'"
                  ></v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar>
                  <v-img
                    :lazy-src="$defaultProfileImage"
                    :src="$assetAPIURL(item.profile_logo)"
                    aspect-ratio="1"
                    class="margin-auto grey lighten-5 custom-grey-border"
                    transition="fade-transition"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >#{{ item.barcode }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >{{ item.display_name }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action class="align-self-center">
                  <v-list-item-subtitle
                    class="text-lowercase pb-2 font-weight-500 font-size-14"
                    >{{ item.primary_email }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    class="text-lowercase font-weight-500 font-size-14"
                    >{{ item.primary_phone }}</v-list-item-subtitle
                  >
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </div>
          <div class="mx-2" v-if="!isDeliveryDetail">
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  flat
                  label="Delivery Date Range"
                  hide-details
                  solo
                  :disabled="serviceFormLoading"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on:click:clear="clearFilter('dates')"
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedDate"
                  color="cyan"
                ></v-text-field>
              </template>
              <v-date-picker
                color="cyan"
                range
                :disabled="serviceFormLoading"
                v-on:change="getServiceForm"
                v-model="dates"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="ml-2 text-right">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="serviceFormLoading"
                  v-on:click="getServiceForm"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>Search</span>
            </v-tooltip>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="serviceFormLoading"
                  v-on:click="getServiceForm"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Reload</span>
            </v-tooltip>
            <template v-if="false && getPermission('service-form:create')">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2 custom-bold-button white--text"
                    color="cyan"
                    :disabled="serviceFormLoading"
                    v-on:click="createServiceForm"
                  >
                    <v-icon>mdi-file-document-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Create Service Form</span>
              </v-tooltip>
            </template>
          </div>
        </v-layout>
      </v-container>
      <v-skeleton-loader
        type="text@5"
        class="custom-skeleton table-rows-text"
        v-if="serviceFormLoading"
      ></v-skeleton-loader>
      <v-simple-table
        v-else
        class="inner-simple-table custom-border-top"
        fixed-header
      >
        <template v-slot:default>
          <tbody>
            <template v-if="lodash.isEmpty(serviceForms) === false">
              <tr class="custom-border-bottom">
                <td class="font-size-16 pl-3 py-2 font-weight-700">#</td>
                <td class="font-size-16 pl-3 py-2 font-weight-700">
                  Timesheet #
                </td>
                <td class="font-size-16 pl-3 py-2 font-weight-700">
                  Service Form
                </td>
                <td
                  class="font-size-16 pl-3 py-2 font-weight-700"
                  v-if="!isDeliveryDetail"
                >
                  Delivery
                </td>
                <td
                  class="font-size-16 pl-3 py-2 font-weight-700"
                  v-if="!isDeliveryDetail"
                >
                  Engineer
                </td>
                <td
                  width="150"
                  class="font-size-16 pl-3 py-2 font-weight-700"
                  align="center"
                >
                  Status
                </td>
                <td
                  width="150"
                  class="font-size-16 pl-3 py-2 font-weight-700"
                  align="center"
                >
                  Download
                </td>
              </tr>
              <template v-for="(row, index) in serviceForms">
                <v-hover v-slot="{ hover }" :key="index">
                  <tr>
                    <td
                      v-on:click="serviceFormDetail(row)"
                      class="cursor-pointer font-size-16 font-weight-500"
                      width="200"
                    >
                      <Barcode :barcode="row.barcode"></Barcode>
                    </td>
                    <td
                      v-on:click="serviceFormDetail(row)"
                      class="cursor-pointer font-size-16 font-weight-500"
                      width="200"
                    >
                      <span v-if="row.timesheet">
                        <Barcode :barcode="row.timesheet.barcode"></Barcode>
                      </span>
                    </td>
                    <td
                      v-on:click="serviceFormDetail(row)"
                      class="cursor-pointer font-size-16 font-weight-500"
                    >
                      <p class="m-0 py-2 custom-nowrap-ellipsis">
                        {{ row.title }}
                      </p>
                    </td>
                    <td
                      v-on:click="serviceFormDetail(row)"
                      class="cursor-pointer font-size-16 font-weight-500"
                      v-if="!isDeliveryDetail"
                    >
                      <template v-if="lodash.isEmpty(row.visit) === false">
                        <Barcode :barcode="row.visit.barcode"></Barcode>
                        <p
                          class="m-0 custom-nowrap-ellipsis font-size-16 font-weight-500"
                        >
                          {{ formatDateTime(row.visit.started_at) }} -
                          {{ formatTime(row.visit.finished_at) }}
                        </p>
                      </template>
                      <template v-else> - </template>
                    </td>
                    <td
                      v-on:click="serviceFormDetail(row)"
                      class="cursor-pointer font-size-16 font-weight-500"
                      v-if="!isDeliveryDetail"
                    >
                      <p
                        class="m-0 py-2 custom-nowrap-ellipsis"
                        v-if="lodash.isEmpty(row.visit) === false"
                        :class="{
                          'text--secondary': lodash.isEmpty(
                            row.visit.engineers
                          ),
                        }"
                      >
                        {{ engineerDisplayText(row.visit.engineers) }}
                      </p>
                      <p
                        v-else
                        class="m-0 py-2 custom-nowrap-ellipsis text--secondary"
                      >
                        {{ engineerDisplayText() }}
                      </p>
                    </td>
                    <td
                      width="150"
                      class="font-size-16 font-weight-500"
                      align="center"
                    >
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            class="mx-2 d-inline custom-status font-weight-600 custom-grey-border text-uppercase justify-center"
                            color="cyan"
                            text-color="white"
                            label
                            small
                          >
                            <template v-if="row.is_filled">Submitted</template>
                            <template v-else>Not Submitted Yet</template>
                          </v-chip>
                        </template>
                        <span>Status</span>
                      </v-tooltip>
                    </td>
                    <td
                      width="150"
                      class="font-size-16 font-weight-500"
                      align="center"
                    >
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!row.pdf_url"
                            color="cyan"
                            v-on:click.stop.prevent="downloadFile(row.pdf_url)"
                            icon
                          >
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-btn>
                        </template>
                        <span>Download PDF</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </v-hover>
              </template>
            </template>
            <tr v-else>
              <td colspan="4" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no service form at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Dialog :commonDialog="detailDialog" :dialogWidth="dialogWidth">
        <template v-slot:title>
          <v-row>
            <v-col md="8">
              <p class="m-0 custom-nowrap-ellipsis">
                {{ serviceFormInfo.title }}
              </p>
            </v-col>
            <v-col md="4">
              <v-layout class="justify-end">
                <template
                  v-if="
                    serviceFormInfo.can_update &&
                    getPermission('service-form:update')
                  "
                >
                  <v-btn
                    v-if="!serviceFormInfo.is_filled"
                    :loading="formLoading"
                    :disabled="formLoading"
                    v-on:click="startSaveForm('save')"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                  >
                    Save
                  </v-btn>
                  <v-btn
                    v-if="!serviceFormInfo.is_filled"
                    :loading="formLoading"
                    :disabled="formLoading"
                    v-on:click="startSaveForm('submit')"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                  >
                    Submit
                  </v-btn>
                </template>
                <v-menu
                  transition="slide-y-transition"
                  bottom
                  content-class="custom-menu-list"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="formLoading"
                      class="mx-2 custom-bold-button white--text"
                      color="cyan"
                      v-bind="attrs"
                      v-on="on"
                    >
                      More... <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template>
                      <v-list-item
                        :disabled="!serviceFormInfo.pdf_url"
                        v-on:click="downloadFile(serviceFormInfo.pdf_url)"
                        link
                      >
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue"
                            >mdi-file-pdf-box</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-500 font-size-14"
                          >PDF Download</v-list-item-title
                        >
                      </v-list-item>
                      <template v-if="getPermission('service-form:delete')">
                        <v-list-item
                          :disabled="serviceFormInfo.is_filled"
                          v-on:click="deleteServiceForm"
                          link
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue"
                              >mdi-delete</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >Delete</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </template>
                  </v-list>
                </v-menu>
                <v-btn
                  v-on:click="closeDialog"
                  :disabled="formLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  >Close
                </v-btn>
              </v-layout>
            </v-col>
          </v-row>
        </template>
        <template v-slot:body>
          <v-form
            ref="serviceDataForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="startSaveForm('')"
            class="m-4"
          >
            <ServiceFormTemplate
              :serviceForm="serviceFormInfo"
              :detail="detail"
              :customer="customer"
              :property="property"
              :billing="billing"
              :contactPerson="contactPerson"
            ></ServiceFormTemplate>
          </v-form>
          <template v-if="getPermission('service-form:delete')">
            <ServiceFormDelete
              :deleteDialog="ServiceFormDeleteDialog"
              :requestUrl="ServiceFormDeleteRequestURL"
              v-on:delete:success="ServiceFormDeleteSuccess"
              v-on:delete:close="ServiceFormDeleteDialog = false"
            ></ServiceFormDelete>
          </template>
        </template>
      </Dialog>
      <template v-if="serviceFormDialog">
        <CreateServiceForm
          :isDeliveryDetail="isDeliveryDetail"
          :serviceFormDialog="serviceFormDialog"
          :detail="detail"
          :visit="visit"
          v-on:create:service-form="getServiceForm"
          v-on:close-dialog="serviceFormDialog = false"
        ></CreateServiceForm>
      </template>
    </v-container>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ServiceFormTemplate from "@/view/pages/service-form/Service-Form-Template.vue";
import ServiceFormDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import CreateServiceForm from "@/view/pages/job/partials/Create-Service-Form.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { DOWNLOAD } from "@/core/services/store/request.module";
import { saveAs } from "file-saver";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, JobRequestMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    visit: {
      type: Object,
      default() {
        return new Object();
      },
    },
    isDeliveryDetail: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Barcode,
    Dialog,
    ServiceFormDelete,
    ServiceFormTemplate,
    CreateServiceForm,
  },
  watch: {
    visit: {
      deep: true,
      immediate: true,
      handler(param) {
        this.filter.visit = this.lodash.toSafeInteger(param.id);
      },
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.job = this.lodash.toSafeInteger(param.id);
        this.getServiceForm();
        this.getEngineerList();
        this.getDeliveryList();
      },
    },
  },
  data() {
    return {
      serviceForms: [],
      job: 0,
      detailDialog: false,
      pdfDownloading: false,
      serviceFormDialog: false,
      serviceFormInfo: {},
      attributes: [],
      ServiceFormDeleteDialog: false,
      ServiceFormDeleteRequestURL: "",
      dates: [],
      engineerList: [],
      visitList: [],
      filter: {
        search: null,
        engineer: 0,
        visit: 0,
      },
      timeout: null,
      timeoutLimit: 500,
      serviceFormLoading: true,
    };
  },
  methods: {
    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },
    createServiceForm() {
      this.serviceFormDialog = true;
    },
    closeDialog() {
      this.detailDialog = false;
      this.serviceFormInfo = {};
    },
    serviceFormDetail(param) {
      this.detailDialog = true;
      this.serviceFormInfo = param;
    },
    downloadSingleServiceForm(ticket, title, service_form_id) {
      const _this = this;
      _this.pdfDownloading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: `job/${ticket}/service-forms/${service_form_id}/download`,
        })
        .then(({ data }) => {
          saveAs(data, title + ".pdf");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pdfDownloading = false;
        });
    },
    downloadSingleExcelServiceForm(ticket, title, service_form_id) {
      const _this = this;
      _this.pdfDownloading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: `job/${ticket}/service-forms/${service_form_id}/excel/download`,
        })
        .then(({ data }) => {
          saveAs(data, title + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pdfDownloading = false;
        });
    },
    downloadServiceForm() {
      const _this = this;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: `job/${_this.serviceFormInfo.ticket}/service-forms/${_this.serviceFormInfo.id}/download`,
        })
        .then(({ data }) => {
          saveAs(data, _this.serviceFormInfo.title + ".pdf");
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    downloadServiceExcelForm() {
      const _this = this;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: `job/${_this.serviceFormInfo.ticket}/service-forms/${_this.serviceFormInfo.id}/excel/download`,
        })
        .then(({ data }) => {
          saveAs(data, _this.serviceFormInfo.title + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    startSaveForm(param) {
      ServiceFormEventBus.$emit("start:save-service-form", param);
    },
    ServiceFormDeleteSuccess() {
      this.ServiceFormDeleteDialog = false;
      this.ServiceFormDeleteRequestURL = "";
      this.getServiceForm();
      this.closeDialog();
      this.$emit("update:service-form", true);
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getServiceForm();
    },
    deleteServiceForm() {
      this.ServiceFormDeleteDialog = true;
      this.ServiceFormDeleteRequestURL =
        "job/" +
        this.detail.id +
        "/visit/" +
        this.serviceFormInfo.visit.id +
        "/service-form/" +
        this.serviceFormInfo.id;
    },
    getServiceForm() {
      const _this = this;
      let filter = {
        search: _this.filter.search,
        visit: _this.lodash.toSafeInteger(_this.filter.visit),
        engineer: _this.lodash.toSafeInteger(_this.filter.engineer),
        daterange: _this.dates,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }
      clearTimeout(_this.timeout);
      _this.serviceFormLoading = true;
      _this.timeout = setTimeout(function () {
        _this
          .getServiceForms(_this.job, { filter })
          .then((response) => {
            _this.serviceForms = response;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.serviceFormDialog = false;
            _this.serviceFormLoading = false;
            _this.detailDialog = false;
            _this.serviceFormInfo = new Object();
          });
      }, _this.timeoutLimit);
    },
    getEngineerList() {
      const _this = this;
      if (_this.isDeliveryDetail) {
        return false;
      }
      _this
        .getEngineers(_this.job)
        .then(({ rows }) => {
          if (_this.lodash.isEmpty(rows) === false) {
            _this.engineerList = rows.map((row) => {
              return row.engineer;
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getDeliveryList() {
      const _this = this;
      if (_this.isDeliveryDetail) {
        return false;
      }
      _this
        .getDeliveriesForFilter(_this.job)
        .then((response) => {
          _this.visitList = response;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        for (let i = 0; i < param.length; i++) {
          if (_this.lodash.isEmpty(param[i].engineer) === false) {
            display_name.push(param[i].engineer.display_name);
          }
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
  },
  mounted() {
    this.getServiceForm();
    this.getEngineerList();
    this.getDeliveryList();
  },
  beforeDestroy() {
    /*ServiceFormEventBus.$off("save:service-form");
    ServiceFormEventBus.$off("close:loader");
    ServiceFormEventBus.$off("start:loader");
    ServiceFormEventBus.$off("update:service-form-attributes");*/
  },
  created() {
    const _this = this;

    ServiceFormEventBus.$on("save:service-form", function () {
      _this.getServiceForm();
      _this.detailDialog = false;
      _this.serviceFormInfo = new Object();
    });
    ServiceFormEventBus.$on("close:loader", function () {
      _this.formLoading = false;
    });
    ServiceFormEventBus.$on("reload:service-form", function () {
      _this.getServiceForm();
    });
    ServiceFormEventBus.$on("start:loader", function () {
      _this.formLoading = true;
    });

    ServiceFormEventBus.$on(
      "update:service-form-attributes",
      function (argument) {
        _this.attributes = argument;
      }
    );
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 80);
    },
  },
};
</script>
