var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"job-teams",attrs:{"fluid":""}},[(!_vm.isDeliveryDetail)?_c('v-container',[_c('v-layout',{staticClass:"justify-end"},[_c('div',{staticClass:"mr-2"},[_c('v-text-field',{attrs:{"clearable":"","dense":"","filled":"","flat":"","label":"Search","hide-details":"","solo":"","disabled":_vm.teamLoading,"color":"cyan"},on:{"click:clear":function($event){return _vm.clearFilter('search')},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getEngineerList.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.getEngineerList.apply(null, arguments)}]},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.search"}})],1),(false)?_c('div',{staticClass:"mx-2"},[_c('v-autocomplete',{attrs:{"hide-details":"","items":_vm.visitList,"clearable":"","dense":"","flat":"","filled":"","label":"Delivery","item-color":"cyan","color":"cyan","solo":"","disabled":_vm.teamLoading,"item-text":"barcode","item-value":"id"},on:{"change":_vm.getEngineerList},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Delivery(s) Found.')}})],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"m-0 font-size-16 text-ellipsis text-capitalize"},[_vm._v(" "+_vm._s(item.barcode)+" ("+_vm._s(_vm.formatDateTime(item.started_at))+" - "+_vm._s(_vm.formatTime(item.finished_at))+") ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-size-16 pl-2 text-ellipsis pb-1 text-capitalize"},[_vm._v(_vm._s(item.barcode)+" ("+_vm._s(_vm.formatDateTime(item.started_at))+" - "+_vm._s(_vm.formatTime(item.finished_at))+")")]),_c('v-list-item-subtitle',{staticClass:"font-size-14 pl-2 text-ellipsis pb-1"},[_vm._v(_vm._s(item.title))])],1)]}}],null,false,120467150),model:{value:(_vm.filter.visit),callback:function ($$v) {_vm.$set(_vm.filter, "visit", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.visit"}})],1):_vm._e(),(false)?_c('div',{staticClass:"mx-2"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","flat":"","label":"Delivery Date Range","hide-details":"","solo":"","disabled":_vm.teamLoading,"prepend-inner-icon":"mdi-calendar","readonly":"","clearable":"","value":_vm.formattedDate,"color":"cyan"},on:{"click:clear":function($event){return _vm.clearFilter('dates')}}},'v-text-field',attrs,false),on))]}}],null,false,2188948983),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"color":"cyan","range":"","disabled":_vm.teamLoading},on:{"change":_vm.getEngineerList},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1):_vm._e(),_c('div',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 custom-bold-button white--text",attrs:{"color":"cyan","tile":"","disabled":_vm.teamLoading},on:{"click":_vm.getEngineerList}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,false,2930624024)},[_c('span',[_vm._v("Search")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 custom-bold-button white--text",attrs:{"color":"cyan","tile":"","disabled":_vm.teamLoading},on:{"click":_vm.getEngineerList}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,2184294568)},[_c('span',[_vm._v("Reload")])])],1)])],1):_vm._e(),(_vm.teamLoading)?_c('v-skeleton-loader',{staticClass:"custom-skeleton table-rows-text",attrs:{"type":"text@5"}}):_c('v-simple-table',{staticClass:"inner-simple-table",class:{ 'custom-border-top': !_vm.isDeliveryDetail },attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"custom-border-bottom"},[_c('td',{staticClass:"font-size-16 font-weight-700",attrs:{"width":"100"}},[_vm._v("#")]),_c('td',{staticClass:"font-size-16 font-weight-700",attrs:{"width":"50"}}),_c('td',{staticClass:"font-size-16 font-weight-700"},[_vm._v("Engineer")]),(!_vm.isDeliveryDetail)?_c('td',{staticClass:"font-size-16 font-weight-700"},[_vm._v(" Delivery ")]):_vm._e(),_c('td',{staticClass:"font-size-16 font-weight-700",attrs:{"width":"100"}})])]),_c('tbody',[(_vm.lodash.isEmpty(_vm.teams) === false)?_vm._l((_vm.teams),function(data,index){return _c('tr',{key:index},[_c('td',[_c('Barcode',{attrs:{"barcode":data.engineer.barcode}})],1),_c('td',[_c('v-list-item-avatar',{attrs:{"size":"50"}},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"margin-auto white--background",attrs:{"lazy-src":_vm.$defaultProfileImage,"src":_vm.$assetAPIURL(data.engineer.profile_logo),"aspect-ratio":"1","transition":"fade-transition"}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.engineer.display_name))])])],1)],1),_c('td',{staticClass:"py-2"},[_c('v-list-item-title',{staticClass:"font-size-16 font-weight-500 custom-nowrap-ellipsis",domProps:{"innerHTML":_vm._s(data.engineer.display_name)}}),_c('v-list-item-subtitle',{staticClass:"font-size-16 font-weight-500 custom-nowrap-ellipsis text--secondary",domProps:{"innerHTML":_vm._s(data.engineer.primary_email)}}),_c('v-list-item-subtitle',{staticClass:"font-size-16 font-weight-500 custom-nowrap-ellipsis text--secondary",domProps:{"innerHTML":_vm._s(data.engineer.primary_phone)}})],1),(!_vm.isDeliveryDetail)?_c('td',[(_vm.lodash.isEmpty(data.visit) === false)?[_c('Barcode',{attrs:{"barcode":data.visit.barcode}}),_c('p',{staticClass:"m-0 custom-nowrap-ellipsis font-size-16 font-weight-500"},[_vm._v(" "+_vm._s(_vm.formatDateTime(data.visit.started_at))+" - "+_vm._s(_vm.formatTime(data.visit.finished_at))+" ")])]:[_vm._v(" - ")]],2):_vm._e(),_c('td',[_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.deleteEngineerConfirm(data)}}},[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-delete")])],1)],1)])}):[_c('tr',[_c('td',{attrs:{"colspan":_vm.isDeliveryDetail ? 4 : 5}},[_c('p',{staticClass:"m-0 row-not-found text-center font-weight-500 font-size-16"},[_vm._v(" Sorry! No Engineer(s) Found. ")])])])]],2)]},proxy:true}])}),_c('EngineerDelete',{attrs:{"deleteDialog":_vm.engineerDeleteDialog,"requestUrl":_vm.engineerDeleteRequestURL},on:{"delete:success":_vm.engineerDeleteSuccess,"delete:close":function($event){_vm.engineerDeleteDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }